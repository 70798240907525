import axios from "axios";
import { baseURLCommApi } from "../../../utils/http/http-common";
import { apiEndPoints } from "../../../utils/http";

export const getSentNotification = async (userId) => {
  try {
    const response = await axios.get(
      `${baseURLCommApi}${apiEndPoints.GetSentNotification_CommApi}/${userId}`
    );
    return response;
  } catch (error) {
    return error.config.data;
  }
};

export const FetchNotifications = async (userId) => {
  try {
    const response = await axios.get(
      `${baseURLCommApi}${apiEndPoints.GetSentNotification_CommApi}/${userId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const MarkReadNotification = async (notificationId) => {
  try {
    const response = await axios.post(
      `${baseURLCommApi}${apiEndPoints.MarkReadNotification_CommApi}/${notificationId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const MarkReadAllNotification = async (userId) => {
  try {
    const response = await axios.post(
      `${baseURLCommApi}${apiEndPoints.MarkReadAllNotification_CommApi}/${userId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
