import React, { useEffect, useState } from "react";
import AdvanceIntroductionPage from "./AdvanceIntroductionPage";
import AdvanceScreeningQA from "./AdvanceScreeningQA";
import AdvanceReport from "./AdvanceReport";
import { useLocation } from "react-router-dom";

function AdvanceScreening() {
  const location = useLocation();
  const [advanceIntroPage, setAdvanceIntroPage] = useState();
  console.log(advanceIntroPage, "testing");
  console.log(location.state, "location state data");

  useEffect(() => {
    // location.state.activeScreeningCategoryID !== 2 this condition add to show advance screening introduction
    // when user start advance screening journey after complete profiler form on click Advance Screening button instead of I will do it later
    if (
      location.state.currentScreeningStatus === "Pending" &&
      location.state.activeScreeningCategoryID !== 2
    ) {
      setAdvanceIntroPage(false);
    } else {
      setAdvanceIntroPage(true);
    }
  }, []);

  return (
    <>
      {advanceIntroPage ? (
        <>
          <AdvanceIntroductionPage setAdvanceIntroPage={setAdvanceIntroPage} />
        </>
      ) : (
        <>
          <AdvanceScreeningQA />
        </>
      )}
      {/* <AdvanceIntroductionPage /> */}
      {/* <AdvanceReport /> */}
    </>
  );
}

export default AdvanceScreening;
