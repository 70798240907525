import { useState } from "react";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateStudentScreeningStatus } from "../Api/ScreeningApi";
import Loader from "../../../../utils/loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../../modal/Modal";
import { reScreening } from "../Api/CaseTransferApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ScreeningDashboard from "../ScreeningDashboard";

function Screening1(props) {
  const {
    scrData,
    tabName,
    description,
    setShowQna,
    setData,
    tabId,
    tabNumber,
    loader,
  } = props;

  const [showToaster, setShowToaster] = useState(false);
  const revierwerId = JSON.parse(localStorage.getItem("userTypeId"));
  const [showReScreeningModal, setShowReScreeningModal] = useState(false);
  const [getStudentId, setStudentId] = useState(0);

  const getTranslateText = useSelector(getTranslatedTextSelector);
  const navigate = useNavigate();
  const userId = localStorage.getItem("id");

  const updateStudentStatus = async (data) => {
    const tab_id =
      tabName === "All" ? data.activeScreeningCategoryID + 1 : tabId;
    updateStudentScreeningStatus(data.studentID, tab_id)
      .then((res) => {
        if (tab_id === 3) {
          if (res === "Success") {
            navigate("/user/advance-screening", { state: data });
            localStorage.setItem("tabsId", 3);
          } else {
            setShowToaster(true);
            toast.warn(res, { style: { fontSize: "14px" } });
          }
        } else {
          navigate("/user/instruction", { state: data });
          localStorage.setItem("tabsId", 2);
        }
      })
      .catch((error) => {
        console.log(error, "errroor");
      });
    // localStorage.setItem("tabsId", 2);
  };

  // Function/Condtion Continue to message //
  const doneConditionMessage = (screeningCatId) => {
    if (screeningCatId === 1) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "label_screening_2"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "label_screening_2"
            )[0]?.keyValue
          : "Profiler Form"
        : "Profiler Form";
    } else if (screeningCatId === 2) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_advance_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_advance_screening"
            )[0]?.keyValue
          : "Advance Screening"
        : "Advance Screening";
    } else {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_intervention"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_intervention"
            )[0]?.keyValue
          : "Advance Intervention"
        : "Advance Intervention";
    }
  };

  // Function/Condtion Continue to message //
  const pendingConditionMessage = (screeningCatId) => {
    if (screeningCatId === 1) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "first_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "first_screening"
            )[0]?.keyValue
          : "Screening 1"
        : "Screening 1";
    } else if (screeningCatId === 2) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "label_screening_2"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "label_screening_2"
            )[0]?.keyValue
          : "Profiler Form"
        : "Profiler Form";
    } else {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_advance_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_advance_screening"
            )[0]?.keyValue
          : "Advance Screening"
        : "Advance Screening";
    }
  };

  const reScreeningOnClick = async (studentId) => {
    await reScreening(studentId, userId).then((response) => {
      if (response?.data?.message === "Success") {
        localStorage.setItem("tabsId", 1);
        setShowReScreeningModal(false);
        window.location.reload(false);
      }
    });
  };

  console.log(scrData, "src data");
  console.log(tabNumber, "tabNumber");

  return (
    <>
      <div className="">
        <div className="lg:p-[0px] md:p-[30px] flex flex-row flex-wrap md:overflow-y-scroll md:h-[calc(100vh-33vh)] fh-[calc(100vh-40vh)] h=[100vh] no-scrollbar">
          {loader !== true ? (
            <>
              {scrData?.length === 0 ? (
                <>
                  <div className="flex flex-col justify-center items-center lg:w-[80%] m-auto py-[0px] h-[100%] ">
                    <img
                      src="../Images/screen1.svg"
                      alt="Screening 1"
                      className="lg:w-[30%] lg:mb-[30px]"
                    />
                    <div>
                      <h3 className="text-[#4D505C] font-semibold text-[18px] mb-[10px]">
                        {/* Screening 1 */}
                        {tabName}
                      </h3>
                      <p className="text-[#5F5982] font-normal text-[16px] mb-[30px]">
                        {description?.description}
                      </p>
                    </div>

                    <button
                      className={`${
                        revierwerId === 9 ? "hiddens" : ""
                      } bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[10px] text-white py-[10px] lg:px-[80px] px-[40px] text-white"`}
                      // onClick={studentForm}
                      onClick={() => navigate("/user/add_student_form")}
                    >
                      Add Students +
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {scrData.map((data) => (
                    <div className="rounded-[12px] bg-[#fff] xl:w-[44%] lg:w-[43%] w-[100%] lg:mx-[24px] my-[15px] shadow-[0px_0px_20px_#48456C21] h-fit">
                      <div className="flex flex-row justify-between py-4">
                        <p
                          className={` ${
                            data.currentScreeningStatus === "Done"
                              ? "bg-[#E2FFE0]"
                              : data.currentScreeningStatus === "Pending"
                              ? "bg-[#FFFAE0]"
                              : "bg-[#FFE3E3]"
                          } ${
                            data.currentScreeningStatus === "Done"
                              ? "text-[#02B10F]"
                              : data.currentScreeningStatus === "Pending"
                              ? "text-[#FCA019]"
                              : "text-[#DC0000]"
                          } p-1 rounded-tr-[15px] rounded-br-[15px] text-[12px] px-[15px]`}
                        >
                          {data.currentScreeningStatus === "Done" ? (
                            <>
                              {getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_done"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                  : "Done "
                                : "Done "}
                            </>
                          ) : data.currentScreeningStatus === "Pending" ? (
                            <>
                              {getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_pending"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_pending"
                                    )[0]?.keyValue
                                  : " Pending "
                                : " Pending "}
                            </>
                          ) : (
                            data.currentScreeningStatus
                          )}
                        </p>
                        <p
                          className="text-[#3C57AD] mr-[20px] text-[16px] cursor-pointer"
                          onClick={() => {
                            // setProfileVisible(true);
                            // setGetStudentId(data.studentID);
                            navigate("/user/student-profile", {
                              state: { data },
                            });
                          }}
                        >
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_view_profiler"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item?.pageKey === "key_view_profiler"
                                )[0]?.keyValue
                              : "View profiler"
                            : "View profiler"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center mx-[30px] mb-[20px]">
                        <img
                          // src="../Images/student-screening-pic.png"
                          src={
                            data.studentPhoto?.toLowerCase() === "na" ||
                            data.studentPhoto === "undefined"
                              ? "../Images/icon-student-thumbnail.png"
                              : data.studentPhoto
                          }
                          alt=""
                          className="border-[#b6bacc] rounded-[50%] border-2 w-[70px] h-[70px]"
                        />
                        <div className="flex flex-col text-left pl-[15px]">
                          <p className="mb-[10px] font-medium">
                            {data.studentName}
                          </p>
                          <p className="text-[#848BAA] text-[14px]">
                            {data.grade}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${
                          revierwerId === 9 ? "hiddens" : ""
                        } flex flex-row justify-between bg-[#F4F8FC] px-[30px] py-[10px]"`}
                      >
                        <p
                          className={`${
                            (data.activeScreeningCategoryID === 3) &
                            (data.currentScreeningStatus === "Done")
                              ? "hidden"
                              : "flex"
                          }text-[#101942] text-[16px] text-left `}
                        >
                          {data.currentScreeningStatus ===
                          "Grade Group Change" ? (
                            "Re-screening required"
                          ) : (
                            <>
                              {getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "key_continue_with"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_continue_with"
                                    )[0]?.keyValue
                                  : "Continue with"
                                : "Continue with"}
                              &nbsp;
                              {data.currentScreeningStatus === "Done" ||
                              data.currentScreeningStatus === "Grade Change" ? (
                                <>
                                  {" "}
                                  {
                                    <p>
                                      {" "}
                                      {doneConditionMessage(
                                        data.screeningCategoryId
                                      )}
                                    </p>
                                  }
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {
                                    <p>
                                      {" "}
                                      {pendingConditionMessage(
                                        data.screeningCategoryId
                                      )}
                                    </p>
                                  }
                                </>
                              )}
                            </>
                          )}
                        </p>
                        <img
                          alt="arrow"
                          src="../Images/arrow-icon.svg"
                          className={`${
                            (data.activeScreeningCategoryID === 3) &
                            (data.currentScreeningStatus === "Done" ||
                              data.currentScreeningStatus === "Grade Change")
                              ? "w-[0px]"
                              : "w-[40px]"
                          } cursor-pointer
                         
                          `}
                          onClick={() => {
                            setData(data);
                            if (data.currentScreeningStatus === "Pending") {
                              if (data.activeScreeningCategoryID === 1) {
                                setShowQna(true);
                              } else if (data.activeScreeningCategoryID === 2) {
                                //profiler form
                                navigate("/user/profiler-form", {
                                  state: data,
                                });
                                // updateStudentStatus(data); // need to change //
                              } else {
                                navigate("/user/advance-screening", {
                                  state: data,
                                });
                                // advance screening
                              }
                            } else if (
                              data.currentScreeningStatus ===
                              "Grade Group Change"
                            ) {
                              setStudentId(data.studentID);
                              setShowReScreeningModal(true);
                            } else {
                              if (data.activeScreeningCategoryID === 1) {
                                updateStudentStatus(data);
                              } else if (data.activeScreeningCategoryID === 2) {
                                updateStudentStatus(data); // need to change for advance screening
                              } else {
                                navigate("/user/intervention");
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[80%] w-full">
              <Loader type="spinningBubbles" color="#2e376c" />
            </div>
          )}
        </div>
      </div>
      {showToaster && <ToastContainer />}
      <>
        {/* Already request submit success model */}
        <Modal visible={showReScreeningModal}>
          <div className="relative w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <p
              className="absolute top-4 right-4 bg-[#f6f7fa] rounded-full w-[30px] h-[30px] flex justify-center items-center cursor-pointer z-10"
              onClick={() => setShowReScreeningModal(false)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                style={{ color: "#3c3f4f" }}
              />
            </p>
            <img
              src="../../Images/re-screening.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Re-screening Needed </b>
            </h2>
            <h6 className="text-[14px] text-[#565D7A]">
              The student has been promoted to higher grade level, so
              rescreening is required to ensure appropriate intervention.
            </h6>
            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => {
                //setShowAlreadyAssessedModal(true);
                reScreeningOnClick(getStudentId);
                //navigate("/user/screening-dashboard");
              }}
            >
              Start Re-screening
            </p>
          </div>
        </Modal>
      </>
    </>
  );
}

export default Screening1;
