import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Report from "./Report";
import {
  screeningPhaseOneAnswer,
  screeningPhaseOneQA,
} from "./Api/ScreeningApi";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import Modal from "../../modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import ScreeningDashboard from "./ScreeningDashboard";
import { useMediaQuery } from "react-responsive";
import Loader from "../../../utils/loader/Loader";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";
import "react-toastify/dist/ReactToastify.css";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const ScreeningQA = (props) => {
  const location = useLocation();
  const { studentForm, setStudForm, setShowQna } = location.state
    ? location.state
    : props;

  const [showDashboard, setShowDashboard] = useState(false);
  const [showToaster, setShowToaster] = useState();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const lastQuestionRef = useRef(null);
  let languageId = localStorage.getItem("l_id");
  const student_detail = JSON.parse(localStorage.getItem("student_detail"));
  const user_detail = JSON.parse(localStorage.getItem("user"));
  const [report, setReport] = useState(true);
  const [questionAnswer, setQuestionAnswer] = useState("");
  const [index, setIndex] = useState(1);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const parse = require("html-react-parser");

  const [instruction, setInstruction] = useState();
  const [answerSheet, setAnswerSheet] = useState({
    studentId: student_detail?.id ? student_detail?.id : studentForm?.studentID,
    screeningQuestionOption: [],
    languageId: languageId,
    screeningCategoryId: 1,
    userTypeID: user_detail.userTypeId,
    userID: user_detail.id,
  });

  const [studentReport, setStudentReport] = useState();
  const [noReport, setNoReport] = useState(false);
  const grade = studentForm?.gradeId
    ? studentForm.gradeId
    : studentForm?.gradeid;
  const getQueAnswer = () => {
    setLoader(true);
    screeningPhaseOneQA(grade, languageId)
      .then((response) => {
        if (response?.response.length === 0) {
          setShowQna(false);
          setShowToaster(true);
          toast.warn("Currently , No screening tool available for this Grade");
        } else {
          setQuestionAnswer(response?.response);
          setLoader(false);
        }
        console.log("responseeeeddddd", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAnswer = (item, subItem) => {
    const existingQuestion = answerSheet.screeningQuestionOption.find(
      ({ questionid }) => questionid === item.questionid
    );

    if (existingQuestion) {
      const updatedOptions = answerSheet.screeningQuestionOption.map(
        (option) => {
          if (option.questionid === existingQuestion.questionid) {
            return {
              ...option,
              optionid: subItem.optionid,
            };
          }
          return option;
        }
      );
      setAnswerSheet({
        ...answerSheet,
        screeningQuestionOption: updatedOptions,
      });
    } else {
      const finalData = {
        questionid: item.questionid,
        optionid: subItem.optionid,
      };

      setAnswerSheet({
        ...answerSheet,
        screeningQuestionOption: [
          ...answerSheet.screeningQuestionOption,
          finalData,
        ],
      });

      setIndex(index + 1);
    }
  };

  const handleSubmit = () => {
    screeningPhaseOneAnswer(answerSheet).then((response) => {
      console.log(response, "afterSubmit");
      if (response?.status === 1 && response?.message === "Success") {
        if (response?.response.length === 0) {
          setNoReport(true);
        } else {
          setStudentReport(response?.response);
        }

        setReport(false);
        setLoader(false);
      } else {
        console.log("something going wrong");
      }
    });
  };

  console.log(location?.state, "Screning 1 q n a");
  console.log(studentForm, "Screning 1 student Form");

  useEffect(() => {
    getQueAnswer(languageId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  const getInstruction = (id) => {
    fetchInstruction("screening", languageId).then((response) => {
      if (response.response[0] === undefined) {
        languageId = 1;
        getInstruction(languageId);
      } else {
        setInstruction(response.response[0]);
        //setLoader(false);
      }
    });
  };

  useEffect(() => {
    getInstruction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  const scrollToBottom = () => {
    lastQuestionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [index]);

  // Progress bar//
  const currentQues = answerSheet.screeningQuestionOption.length;
  const totalQues = questionAnswer?.length;
  const progress = (currentQues / totalQues) * 100;
  //   Modal 1 //
  const [showModal, setShowModal] = useState(false);
  // Modal 2 //
  const [showModal2, setShowModal2] = useState(false);
  const [mobileOn, setMobileOn] = useState(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 440px)" });
  useEffect(() => {
    isSmallScreen ? setMobileOn(true) : setMobileOn(false);
  }, [isSmallScreen]);

  console.log(loader, "loader state");

  return (
    <>
      {!showDashboard ? (
        <>
          {loader !== true ? (
            <>
              {report ? (
                <div className="bg-[#F4F4FA] md:py-[30px] lg:px-[50px] px-[0px] md:h-[calc(100%_-_9%)] h-[calc(100%_-_10%)] overflow-y-scroll no-scrollbar">
                  <div className="bg-[#fff] rounded-t-[25px] h-[100%] ">
                    <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#DFE5FF] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row justify-between items-center lg:px-[50px] md:px-[20px] px-[10px] pt-[10px]">
                      <h2 className="text-[#3C3F4F] font-semibold text-left lg:text-[22px] md:text-[18px] text-[14px] py-[20px]  mt-[] ">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_screening_1"
                            )[0].keyValue
                          : "Screening 1"}
                      </h2>
                      <div className="flex flex-row items-center">
                        <ProgressBar
                          percent={progress}
                          filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                          width={`${mobileOn === true ? "70px" : "200px"}`}
                          position={answerSheet.screeningQuestionOption.length}
                          index={answerSheet.screeningQuestionOption.length}
                        />
                        <p className="lg:text-[24px] md:text-[18px] text-[14px] text-[#3C3F4F] font-semibold mx-2">
                          {answerSheet.screeningQuestionOption.length}/
                          {questionAnswer?.length}
                        </p>
                      </div>

                      <div className="flex flex-row">
                        <img
                          src="../Images/book.svg"
                          alt=""
                          className="md:mr-[40px] mr-[10px] w-[20px] cursor-pointer"
                          onClick={() => setShowModal(true)}
                        />
                        <p
                          className="bg-[#f6f7fa] rounded-full py-[8px] w-[40px] h-[40px] cursor-pointer"
                          onClick={() => setShowModal2(true)}
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            size="lg"
                            style={{ color: "#3c3f4f" }}
                            o
                          />
                        </p>
                      </div>
                    </div>
                    <div className="border-t-2 h-[90%] py-[50px]">
                      <div className="w-[95%] lg:w-[75%] md:pr-[60px] pr-[10px] overflow-y-scroll float-right flex flex-col  relative md:h-[90%] h-[100%]">
                        {questionAnswer &&
                          questionAnswer?.slice(0, index)?.map((item, i) => (
                            <div className="mb-[50px] flex flex-col justify-end h-[100%]">
                              <div className="bg-[#F7FBFF] rounded-tl-[4px] rounded-r-[20px] rounded-bl-[20px] p-5">
                                <p className="text-left">
                                  {item?.questionname}
                                </p>
                              </div>
                              <div className="flex flex-row justify-end">
                                {questionAnswer &&
                                  questionAnswer[i].questionoptions.map(
                                    (subItem, subIndex) => (
                                      <div
                                        className=" float-right mt-[30px]"
                                        key={subIndex}
                                      >
                                        <button
                                          className={`${
                                            answerSheet.screeningQuestionOption[
                                              i
                                            ]?.optionid === subItem.optionid
                                              ? "bg-gradient-to-r from-[#122065] "
                                              : "bg-[#fff] text-blue-400"
                                          } hover:text-[#fff] border-[#111E5B] border-2 hover:bg-gradient-to-r from-[#122065] via-[#122065] to-[#40B0F8] text-[#fff] rounded-[10px] md:w-[100px] w-[70px] h-[35px] ml-[50px]`}
                                          onClick={() => {
                                            handleAnswer(item, subItem);
                                          }}
                                        >
                                          {subItem.optionname}
                                        </button>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ))}
                        <div ref={lastQuestionRef}></div>
                        {questionAnswer.length ===
                        answerSheet.screeningQuestionOption.length ? (
                          <button
                            className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white mb-[20px]"
                            onClick={handleSubmit}
                          >
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_save_continue"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item?.pageKey === "key_save_continue"
                                  )[0]?.keyValue
                                : "Save & Continue"
                              : "Save & Continue"}
                            {/* Save & Proceed */}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Report
                  studentReport={studentReport}
                  setStudForm={setStudForm}
                  state={props}
                  noReport={noReport}
                  // setShowQna={setShowQna}
                  studentForm={studentForm}
                  studentId={answerSheet.studentId}
                  gradeID={grade}
                />
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[80%] w-full">
              <Loader type="spinningBubbles" color="#2e376c" />
            </div>
          )}
        </>
      ) : (
        <ScreeningDashboard />
      )}

      {/* Modal Component */}
      <Modal visible={showModal}>
        <div>
          {/* Modal Content */}
          <div className=" lg:w-5/12 md:w-8/12 w-10/12 m-auto ">
            <div className="flex justify-between   bg-gradient-to-r from-[#2C3EA2] to-[#192867] px-6 py-4 rounded-t-[30px]">
              <h2 className="text-xl font-semibold  text-[#fff]  text-left">
                {instruction?.screenName}
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  style={{ color: "#2C3EA2" }}
                />
              </p>
            </div>
            <div className="bg-[#fff] md:px-6 px-3 py-6 rounded-b-[30px]">
              <h5 className="text-center text-2xl font-medium mb-1">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "srneLabel"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "srneLabel"
                      )[0]?.keyValue
                    : "Instructions"
                  : "Instructions"}
              </h5>
              <ol className="list-disc px-6 text-left text-[14px] md:text-[18px]">
                {instruction?.description && parse(instruction?.description)}
              </ol>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] md:px-[50px] px-[30px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center hidden"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Exit Modal */}
      <Modal visible={showModal2}>
        <div>
          {/* Modal Content */}
          <div className=" w-12/12 m-auto ">
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center">
              <img src="../Images/sad_puzzle.svg" alt="Puzzle sad Image" />
              <h5 className="font-semibold text-[20px] my-2">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_want_to_leave"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_want_to_leave"
                      )[0]?.keyValue
                    : "Want to Leave?"
                  : "Want to Leave?"}
              </h5>
              <p className="w-[80%]">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) =>
                        item?.pageKey ===
                        "key_dont_worry_you_can_continue_from_where_you_left"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) =>
                          item?.pageKey ===
                          "key_dont_worry_you_can_continue_from_where_you_left"
                      )[0]?.keyValue
                    : "Don't worry you can continue from where you left."
                  : "Don't worry you can continue from where you left."}
              </p>
              <div className="flex justify-around w-[100%]">
                <button
                  // onClick={() => setShowQna(false)}
                  onClick={() => {
                    setShowDashboard(true);
                    navigate("/user/screening-dashboard");
                    setShowModal2(false);
                  }}
                  className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_leave"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "key_leave"
                        )[0]?.keyValue
                      : "Leave"
                    : "Leave"}
                </button>
                <button
                  onClick={() => setShowModal2(false)}
                  className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_cancel"
                      )[0].keyValue
                    : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {showToaster && <ToastContainer />}
    </>
  );
};

export default ScreeningQA;
