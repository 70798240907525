import React, { useEffect, useRef, useState } from "react";
import "./PersonalDetails.css";
import backBtn from "../../../../assets/images/back-btn.svg";
import backButton from "../../../../assets/images/back-button.svg";
import profilePic from "../../../../assets/images/demo-profile-img.png";
import tickIcon from "../../../../assets/images/tick-icon.png";
import puzzleBg from "../../../../assets/images/puzzle-bg.svg";
import pendingIcon from "../../../../assets/images/pending-icon.svg";
import {
  classroomObservation,
  personalStudentData,
  profilerFormReport,
  screeningPhaseTwoStudentPendingList,
} from "../Api/ScreeningApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import Loader from "../../../../utils/loader/Loader";
import ScreeningQA from "../ScreeningQA";
import { decrypt } from "../../../encryption/Encryption";
import Modal from "../../../modal/Modal";
import {
  studentCaseTransferHistory,
  isVisibleViewHistoryButton,
} from "../Api/CaseTransferApi";
import ConcernAdvanceScreeningQA from "../AdvanceScreening/ConcernAdvanceScreeningQA";
import { toast } from "react-toastify";

function PersonalDetails(props) {
  const { studentId, currentScreeningStatus, ShowQna } = props;
  const languageId = localStorage.getItem("l_id");
  const [studentData, setStudentData] = useState();
  const [decryptdob, setDecryptdob] = useState();
  const [observationData, setObservationData] = useState();
  const [profilerReportData, setProfilerReportData] = useState();
  const [backtoIntervention, setBacktoIntervention] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const dataRef = useRef();
  const [loader, setLoader] = useState(true);
  const student_id = location?.state?.studentId;
  const userId = localStorage.getItem("id");
  let language_id = localStorage.getItem("l_id");
  let [scrCategoryId, setScrCategoryId] = useState();
  const [screeningOneStudentData, setScreeingOneStudentData] = useState([]);
  setScrCategoryId = [1, 2, 3, 4];

  const _studentId =
    location?.state?.data?.studentID ||
    location?.state?.studentID ||
    location?.state?.data?.studentId;

  const studentPersonalDetails = () => {
    personalStudentData(_studentId || student_id).then((response) => {
      console.log(response);
      setStudentData(response);
      setLoader(false);

      // return response;
    });
  };
  // Classroom Observation Api Calling //
  const classroomObser = () => {
    const studentId =
      location?.state?.data?.studentID || location?.state?.data?.studentId;

    classroomObservation(studentId || student_id, languageId).then(
      (response) => {
        setObservationData(response);
        setLoader(false);
      }
    );
  };

  // Profiler Form Report Api Calling //
  const profilerReport = () => {
    const studentId =
      location?.state?.data?.studentID || location?.state?.data?.studentId;

    profilerFormReport(2, languageId, studentId || student_id).then(
      (response) => {
        setProfilerReportData(response);
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    studentPersonalDetails();
  }, []);

  useEffect(() => {
    classroomObser();
  }, []);

  useEffect(() => {
    profilerReport();
  }, []);

  const scrollToData = () => {
    if (dataRef.current) {
      dataRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Age Calculation //
  const birthdate = studentData ? decrypt(studentData?.dob) : "";

  // Calculate the age
  function calculateAge(birthdate) {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate for this year has already occurred
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      // If the birthdate hasn't occurred this year yet, subtract 1 from the age
      return age - 1;
    } else {
      return age;
    }
  }
  const age = calculateAge(birthdate);
  // Age Calcultio End //

  // Student Pending List Api //
  const getPendingStudentData = () => {
    screeningPhaseTwoStudentPendingList(
      userId,
      language_id,
      // scrCategoryId
      3
    ).then((response) => {
      console.log(response.data.response, "dddd");
      if (
        (response.data.error === "GMC0002" && response.data.error === "") ||
        language_id > 3
      ) {
        console.log(language_id, "language_id");
        language_id = 1;
        getPendingStudentData();
      } else {
        setScreeingOneStudentData(response.data.response);
        setLoader(false);
      }

      console.log(response);
    });
  };

  const [showViewHistoryModal, setShowViewHistoryModal] = useState(false);
  const [showHistoryDetails, setShowHistoryDetails] = useState(false);
  const [isVisibleHistoryButton, setIsVisibleHistoryButton] = useState(false);
  const [isVisibleAddConcernButton, setIsVisibleAddConcernButton] =
    useState(false);
  const [histroyNewImageUrl, setHistroyNewImageUrl] = useState("#");
  const dataToPass = {
    sId: _studentId,
    screeningStatus: location?.state?.data?.currentScreeningStatus,
  };
  const [caseTransferHistoryResponse, setCaseTransferHistoryResponse] =
    useState([]);

  const studentCaseTransferHistoryOnClick = async () => {
    await studentCaseTransferHistory(_studentId).then((response) => {
      if (
        response?.data?.message === "Success" &&
        response?.data?.response.length > 0
      ) {
        setShowViewHistoryModal(true);
        setCaseTransferHistoryResponse(response?.data?.response);
      } else {
        setShowViewHistoryModal(true);
      }
    });
  };

  const IsVisibleViewHistoryButton = async () => {
    await isVisibleViewHistoryButton(_studentId).then((response) => {
      setIsVisibleHistoryButton(response[0]?.ViewHistoryButton);
      setIsVisibleAddConcernButton(response[0]?.ViewAddConcernButton);
      setHistroyNewImageUrl(response[0]?.newIconUrl);
    });
  };

  useEffect(() => {
    IsVisibleViewHistoryButton();
  }, []);

  var concern = location?.state?.concern;
  useEffect(() => {
    if (concern === "Add Concern Success") {
      toast.success(
        "New concerns has been updated to the student's classroom observation."
      );
    }
  }, [concern]);

  console.log(location?.state?.data?.studentID, "2. advance concern sid");
  console.log(location?.state?.concern, "add concern");
  return (
    <>
      {loader !== true ? (
        <>
          <div className="grid grid-cols-6 bg-[#F4F4FA] py-5 lg:px-[10px] px-[0px] h-[calc(100vh_-_9vh)]">
            <div className="sm:col-span-2 col-span-6  bg-[#fff] sm:rounded-[30px] rounded-t-[30px] lg:mx-[10px] sm:mx-[10px] mx-[30px] relative z-9">
              {/* Header */}
              <div className="pd-profile p-5 rounded-t-[30px]">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={backBtn}
                    alt="Back Button"
                    // onClick={() => navigate(-1)}
                    onClick={() => {
                      if (
                        location?.state?.data?.setInterventionProfile === true
                      ) {
                        navigate("/user/intervention");
                      } else {
                        navigate("/user/screening-dashboard");
                      }
                    }}
                    // onClick={() => navigate("/user/screening-dashboard")}
                    className="cursor-pointer"
                  />
                  {isVisibleHistoryButton === true ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <img
                        src={histroyNewImageUrl}
                        className={`${
                          histroyNewImageUrl === "#" ? `hidden` : ``
                        } flex flex-col text-right`}
                        alt="new text"
                      ></img>
                      &nbsp;
                      <button
                        style={{
                          textAlign: "right",
                          color: "white",
                          fontSize: "15px",
                        }}
                        className="cursor-pointer"
                        onClick={() => studentCaseTransferHistoryOnClick()}
                      >
                        View history
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {studentData && (
                  <div className="flex flex-col item-center justify-center py-7">
                    <div className="border-[#FD1E0E] border-[3px]  rounded-[100%] w-[80px] m-auto">
                      <img
                        src={
                          studentData.studentPhotoUrl &&
                          (studentData.studentPhotoUrl.toLowerCase() === "na" ||
                            studentData.studentPhotoUrl.toLowerCase() ===
                              "undefined")
                            ? "../Images/icon-student-thumbnail.png"
                            : studentData.studentPhotoUrl
                        }
                        alt="Profile Pic"
                        className="w-[80px] h-[80px] border-[#fff] border-[2px] rounded-[100%] m-auto"
                      />
                    </div>

                    <h4 className="text-[#fff] text-[20px] font-medium">
                      {studentData.studentName}
                    </h4>
                    <p className="text-[#fff] ">{studentData.gradeName}</p>
                  </div>
                )}
              </div>
              {/*  */}
              <div>
                <div className="flex flex-col justify-between items-start lg:px-3 xl:px-8 sm:px-2 px-[30px] sm:py-5 pt-5 relative z-10">
                  {location?.state?.data?.activeScreeningCategoryID === 1 ? (
                    <div className="flex flex-row justify-between w-full items-center">
                      <div className="flex flex-row items-center">
                        {location?.state?.data?.currentScreeningStatus ===
                        "Done" ? (
                          <img
                            src={tickIcon}
                            alt="tick icon"
                            className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                          />
                        ) : (
                          <img
                            src={pendingIcon}
                            alt="tick icon"
                            className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                          />
                        )}

                        <div className="flex flex-col items-start ml-2">
                          <p className="lg:text-[14px] text-[12px] ">
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_screening_1"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_1"
                                  )[0]?.keyValue
                                : "Screening 1"
                              : "Screening 1"}
                          </p>
                          <span
                            className={`lg:text-[14px] text-[12px]  font-medium ${
                              location?.state?.data?.currentScreeningStatus ===
                              "Done"
                                ? "text-[#02b10f]"
                                : "text-[#FDA601]"
                            }`}
                          >
                            {location?.state?.data?.currentScreeningStatus ===
                            "Done" ? (
                              <>
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "label_done"
                                      )[0]?.keyValue
                                    : "Done"
                                  : "Done"}
                              </>
                            ) : (
                              <>
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_pending"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "label_pending"
                                      )[0]?.keyValue
                                    : "Pending"
                                  : "Pending"}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="[&_p]:text-[#3C57AD] [&_p]:text-[14px] [&_p]:font-medium [&_p]:cursor-pointer">
                        {location?.state?.data?.activeScreeningCategoryID ===
                          1 &&
                        location?.state?.data?.currentScreeningStatus ===
                          "Pending" ? (
                          <>
                            <p
                              className="cursor-pointer"
                              onClick={
                                () =>
                                  navigate("/user/screening1-qna", {
                                    state: {
                                      studentForm: location?.state?.data,
                                    },
                                  })
                                // studentForm={location.state.data}
                              }
                            >
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "cont_text"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                  : "Continue"
                                : "Continue"}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              onClick={() =>
                                navigate("/user/screening1-report", {
                                  state: {
                                    studentReport: location.state.data,
                                    studentName:
                                      location.state.data.studentName,
                                  },
                                })
                              }
                              className="py-2"
                            >
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"}
                            </p>
                          </>
                        )}
                      </div>
                      {/* <p className="text-[#3C57AD] font-medium lg:text-[14px] text-[12px] cursor-pointer">
                    {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"} {">"}
                  </p> */}
                    </div>
                  ) : location?.state?.data?.activeScreeningCategoryID === 2 ? (
                    <>
                      <div className="flex flex-row justify-between w-full items-center">
                        <div className="flex flex-row items-start">
                          <div className="after:border-[#dee3f2] after:border-r-2 after:rotate-90 after:border-dotted after:h-[20px] after:w-[15px] after:pb-[5px] mt-[5px]">
                            <img
                              src={tickIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          </div>

                          <div className="flex flex-col items-start ml-2">
                            <p className="lg:text-[14px] text-[12px] ">
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_1"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "label_screening_1"
                                    )[0]?.keyValue
                                  : "Screening 1"
                                : "Screening 1"}{" "}
                            </p>
                            <span
                              className={`lg:text-[14px] text-[12px]  font-medium ${
                                location?.state?.data
                                  ?.currentScreeningStatus === "Done"
                                  ? "text-[#02b10f]"
                                  : "text-[#02b10f]"
                              }`}
                            >
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_done"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                  : "Done"
                                : "Done"}
                            </span>
                          </div>
                        </div>
                        <div className="[&_p]:text-[#3C57AD] [&_p]:text-[14px] [&_p]:font-medium [&_p]:cursor-pointer">
                          {location?.state?.data?.activeScreeningCategoryID ===
                            1 &&
                          location?.state?.data?.currentScreeningStatus ===
                            "Pending" ? (
                            <>
                              <p className="cursor-pointer">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "cont_text"
                                      )[0]?.keyValue
                                    : "Continue"
                                  : "Continue"}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                onClick={() =>
                                  navigate("/user/screening1-report", {
                                    state: {
                                      studentReport: location.state.data,
                                      studentName:
                                        location.state.data.studentName,
                                    },
                                  })
                                }
                                className="py-2"
                              >
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_view_report"
                                      )[0]?.keyValue
                                    : "View Report"
                                  : "View Report"}
                              </p>
                            </>
                          )}
                        </div>
                        {/* <p className="text-[#3C57AD] font-medium lg:text-[14px] text-[12px] cursor-pointer">
                      {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"} {">"}
                    </p> */}
                      </div>
                      <div className="flex flex-row justify-between w-full items-center">
                        <div className="flex flex-row items-center">
                          {location?.state?.data?.currentScreeningStatus ===
                          "Done" ? (
                            <img
                              src={tickIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          ) : (
                            <img
                              src={pendingIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          )}

                          <div className="flex flex-col items-start ml-2">
                            <p className="lg:text-[14px] text-[12px] ">
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_2"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "label_screening_2"
                                    )[0]?.keyValue
                                  : "Profiler Form"
                                : "Profiler Form"}{" "}
                            </p>
                            <span
                              className={`lg:text-[14px] text-[12px]  font-medium ${
                                location?.state?.data
                                  ?.currentScreeningStatus === "Done"
                                  ? "text-[#02b10f]"
                                  : "text-[#FDA601]"
                              }`}
                            >
                              {location?.state?.data?.currentScreeningStatus ===
                              "Done" ? (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "label_done"
                                      )[0]?.keyValue
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "label_done"
                                        )[0]?.keyValue
                                      : "Done"
                                    : "Done"}
                                </>
                              ) : (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "label_pending"
                                      )[0]?.keyValue
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "label_pending"
                                        )[0]?.keyValue
                                      : "Pending"
                                    : "Pending"}
                                </>
                              )}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="[&_p]:text-[#3C57AD] [&_p]:text-[14px] [&_p]:font-medium [&_p]:cursor-pointer">
                          {location?.state?.data?.activeScreeningCategoryID ===
                            2 &&
                          location?.state?.data?.currentScreeningStatus ===
                            "Pending" ? (
                            <>
                              <p
                                className="cursor-pointer"
                                onClick={
                                  () =>
                                    navigate("/user/profiler-form", {
                                      state: location.state.data,
                                    })
                                  // studentForm={location.state.data}
                                }
                              >
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "cont_text"
                                      )[0]?.keyValue
                                    : "Continue"
                                  : "Continue"}
                              </p>
                            </>
                          ) : (
                            <>
                              <p onClick={scrollToData} className="py-2">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_view_report"
                                      )[0]?.keyValue
                                    : "View Report"
                                  : "View Report"}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-row justify-between w-full items-center">
                        <div className="flex flex-row items-start">
                          <div className="after:border-[#dee3f2] after:border-r-2 after:rotate-90 after:border-dotted after:h-[20px] after:w-[15px] mt-[5px] after:pb-[5px]">
                            <img
                              src={tickIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          </div>

                          <div className="flex flex-col items-start ml-2">
                            <p className="lg:text-[14px] text-[12px] ">
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_1"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "label_screening_1"
                                    )[0]?.keyValue
                                  : "Screening 1"
                                : "Screening 1"}
                            </p>
                            <span
                              className={`lg:text-[14px] text-[12px]  font-medium ${
                                location?.state?.data
                                  ?.currentScreeningStatus === "Done"
                                  ? "text-[#02b10f]"
                                  : "text-[#02b10f]"
                              }`}
                            >
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_done"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                  : "Done"
                                : "Done"}
                            </span>
                          </div>
                        </div>
                        <div className="[&_p]:text-[#3C57AD] [&_p]:text-[12px] [&_p]:font-medium [&_p]:cursor-pointer">
                          {location?.state?.data?.activeScreeningCategoryID ===
                            1 &&
                          location?.state?.data?.currentScreeningStatus ===
                            "Pending" ? (
                            <>
                              <p className="cursor-pointer">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "cont_text"
                                      )[0]?.keyValue
                                    : "Continue"
                                  : "Continue"}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                onClick={() =>
                                  navigate("/user/screening1-report", {
                                    state: {
                                      studentReport: location.state.data,
                                      studentName:
                                        location.state.data.studentName,
                                    },
                                  })
                                }
                                className="py-2"
                              >
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_view_report"
                                      )[0]?.keyValue
                                    : "View Report"
                                  : "View Report"}
                              </p>
                            </>
                          )}
                        </div>
                        {/* <p className="text-[#3C57AD] font-medium lg:text-[14px] text-[12px] cursor-pointer">
                      {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"} {">"}
                    </p> */}
                      </div>
                      <div className="flex flex-row justify-between w-full items-center">
                        <div className="flex flex-row items-start">
                          <div
                            classNames="before:border-[#dee3f2] before:border-l-2 before:rotate-90 before:border-dotted before:h-[2px] before:w-[2px]"
                            className="after:border-[#dee3f2] after:border-r-2 after:rotate-90 after:border-dotted after:h-[20px] after:w-[15px] after:pb-[5px]"
                          >
                            <img
                              src={tickIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          </div>

                          <div className="flex flex-col items-start ml-2 ">
                            <p className="lg:text-[14px] text-[12px] ">
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_2"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "label_screening_2"
                                    )[0]?.keyValue
                                  : "Profiler Form"
                                : "Profiler Form"}
                            </p>
                            <span
                              className={`lg:text-[14px] text-[12px]  font-medium ${
                                location?.state?.data
                                  ?.currentScreeningStatus === "Done"
                                  ? "text-[#02b10f]"
                                  : "text-[#02b10f]"
                              }`}
                            >
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_done"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                  : "Done"
                                : "Done"}
                            </span>
                          </div>
                        </div>
                        <div className="[&_p]:text-[#3C57AD] [&_p]:text-[12px] [&_p]:font-medium [&_p]:cursor-pointer">
                          {location?.state?.data?.activeScreeningCategoryID ===
                            2 &&
                          location?.state?.data?.currentScreeningStatus ===
                            "Pending" ? (
                            <>
                              <p className="cursor-pointer">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "cont_text"
                                      )[0]?.keyValue
                                    : "Continue"
                                  : "Continue"}
                              </p>
                            </>
                          ) : (
                            <>
                              <p onClick={scrollToData} className="py-2">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_view_report"
                                      )[0]?.keyValue
                                    : "View Report"
                                  : "View Report"}
                              </p>
                            </>
                          )}
                        </div>
                        {/* <p className="text-[#3C57AD] font-medium lg:text-[14px] text-[12px] cursor-pointer">
                      {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"} {">"}
                    </p> */}
                      </div>
                      <div className="flex flex-row justify-between w-full items-center">
                        <div className="flex flex-row items-center">
                          {location?.state?.data?.currentScreeningStatus ===
                            "Done" ||
                          location?.state?.data?.currentScreeningStatus ===
                            "Grade Change" ? (
                            <img
                              src={tickIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          ) : (
                            <img
                              src={pendingIcon}
                              alt="tick icon"
                              className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            />
                          )}

                          <div className="flex flex-col items-start ml-2">
                            <p className="lg:text-[14px] text-[12px] ">
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "key_advance_screening"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_advance_screening"
                                    )[0]?.keyValue
                                  : "Advance Screening"
                                : "Advance Screening"}
                            </p>
                            <span
                              className={`lg:text-[14px] text-[12px]  font-medium ${
                                location?.state?.data
                                  ?.currentScreeningStatus === "Done" ||
                                location?.state?.data
                                  ?.currentScreeningStatus === "Grade Change"
                                  ? "text-[#02b10f]"
                                  : "text-[#FDA601]"
                              }`}
                            >
                              {location?.state?.data?.currentScreeningStatus ===
                                "Done" ||
                              location?.state?.data?.currentScreeningStatus ===
                                "Grade Change" ? (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "label_done"
                                      )[0]?.keyValue
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "label_done"
                                        )[0]?.keyValue
                                      : "Done"
                                    : "Done"}
                                </>
                              ) : (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "label_pending"
                                      )[0]?.keyValue
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "label_pending"
                                        )[0]?.keyValue
                                      : "Pending"
                                    : "Pending"}
                                </>
                              )}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="[&_p]:text-[#3C57AD] [&_p]:text-[12px] [&_p]:font-medium [&_p]:cursor-pointer">
                          {(location?.state?.data?.activeScreeningCategoryID ===
                            3 &&
                            location?.state?.data?.currentScreeningStatus ===
                              "Pending") ||
                          currentScreeningStatus === "Pending" ? (
                            <>
                              <p
                                className="cursor-pointer"
                                onClick={() => {
                                  navigate("/user/advance-screening", {
                                    state: location.state.data,
                                  });
                                }}
                              >
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "cont_text"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "cont_text"
                                      )[0]?.keyValue
                                    : "Continue"
                                  : "Continue"}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                onClick={() =>
                                  navigate("/user/advance_screening_report", {
                                    state: {
                                      studentID:
                                        location?.state?.data?.studentID ||
                                        location?.state?.data?.studentId,
                                      userID:
                                        location?.state?.data?.userID ||
                                        location?.state?.data?.userId,
                                    },
                                  })
                                }
                                className="py-2"
                              >
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_view_report"
                                      )[0]?.keyValue
                                    : "View Report"
                                  : "View Report"}
                              </p>
                            </>
                          )}
                        </div>
                        {/* <p className="text-[#3C57AD] font-medium lg:text-[14px] text-[12px] cursor-pointer">
                      {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "key_view_report"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "key_view_report"
                                    )[0]?.keyValue
                                  : "View Report"
                                : "View Report"} {">"}
                    </p> */}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <img
                src={puzzleBg}
                alt="Puzzle Background"
                className="absolute bottom-0 hidden sm:block z-0"
              />
              {isVisibleAddConcernButton === true ? (
                <button
                  className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white m-auto text-center my-[30px] z-10 relative addConcern"
                  onClick={() =>
                    navigate("/user/concern-advance-screening", {
                      state: dataToPass,
                    })
                  }
                >
                  Add Concern
                </button>
              ) : (
                ""
              )}
            </div>

            <div className="sm:col-span-4 col-span-6  lg:mx-[10px] sm:mx-[10px] mx-[30px] overflow-y-scroll no-scrollbar ">
              <div className="bg-[#fff] sm:rounded-[30px] rounded-b-[30px]">
                <div className="border-b-[1px] border-[#1d2d7724] py-[15px] px-[20px]">
                  <h3 className="text-left font-semibold text-[#101942] text-[22px]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "txt_personal_details"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "txt_personal_details"
                          )[0]?.keyValue
                        : "Personal Details"
                      : "Personal Details"}
                  </h3>
                </div>
                <div className="p-5">
                  {studentData && (
                    <table className="w-full studentDetails-Table">
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_age"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "field_age"
                                )[0]?.keyValue
                              : "Age"
                            : "Age"}
                        </td>
                        <td>{age}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_gender"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "field_gender"
                                )[0]?.keyValue
                              : "Gender"
                            : "Gender"}
                        </td>
                        <td>{studentData.genderName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "label_fathers"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_fathers"
                                )[0]?.keyValue
                              : "Father's name"
                            : "Father's name"}
                        </td>
                        <td>{studentData.fatherName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "label_father_prof"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_father_prof"
                                )[0]?.keyValue
                              : "Father's profession"
                            : "Father's profession"}
                        </td>
                        <td>{studentData.fatherProfessionName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_mother_name"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_mother_name"
                                )[0]?.keyValue
                              : "Mother's name"
                            : "Mother's name"}
                        </td>
                        <td>{studentData.motherName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_mother_prof"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_mother_prof"
                                )[0]?.keyValue
                              : "Mother's profession"
                            : "Mother's profession"}
                        </td>
                        <td>{studentData.motherProfessionName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_residence"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_residence"
                                )[0]?.keyValue
                              : "Residence"
                            : "Residence"}
                        </td>
                        <td>{studentData.residence}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "parent_mob_no"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "parent_mob_no"
                                )[0]?.keyValue
                              : "Parent's Phone Number"
                            : "Parent's Phone Number"}
                        </td>
                        <td>{decrypt(studentData.parentsMobileNo)}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_school_type"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_school_type"
                                )[0]?.keyValue
                              : "Type of school"
                            : "Type of school"}
                        </td>
                        <td>{studentData.schoolTypeName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_school_board"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_school_board"
                                )[0]?.keyValue
                              : "School board"
                            : "School board"}
                        </td>
                        <td>{studentData.schoolBoardName}</td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) =>
                                  item.pageKey === "key_school_ed_details"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item.pageKey === "key_school_ed_details"
                                )[0]?.keyValue
                              : "Educational Details"
                            : "Educational Details"}
                        </td>
                        <td>{studentData.educationTypeName}</td>
                      </tr>
                    </table>
                  )}
                </div>
              </div>
              {(location?.state?.data?.currentScreeningStatus === "Done" &&
                location?.state?.data?.activeScreeningCategoryID === 1) ||
              location?.state?.data?.activeScreeningCategoryID > 1 ? (
                <>
                  {/* Classroom observation */}
                  <div className="bg-[#fff] sm:rounded-[30px] rounded-b-[30px] mt-5">
                    <div className="border-b-[1px] border-[#1d2d7724] py-[15px] px-[20px]">
                      <h3 className="text-left font-semibold text-[#101942] text-[22px]">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) =>
                                item.pageKey === "key_classroom_observation"
                            )[0].keyValue
                            ? getTranslateText?.data?.filter(
                                (item) =>
                                  item.pageKey === "key_classroom_observation"
                              )[0].keyValue
                            : "Classroom Observation"
                          : "Classroom Observation"}
                      </h3>
                    </div>
                    <div className="p-5">
                      {(location?.state?.data?.currentScreeningStatus ===
                        "Done" &&
                        location?.state?.data?.activeScreeningCategoryID ===
                          1) ||
                      location?.state?.data?.activeScreeningCategoryID > 1 ? (
                        <>
                          {/* Screening 1 */}
                          {observationData?.[0] && (
                            <>
                              <div>
                                <h3 className="text-[#101942] font-medium text-[18px] text-left mb-3 text-[#101942]">
                                  {observationData[0]?.screeningName}
                                </h3>
                                <ul className="list-disc text-left px-5 text-[14px] text-[#101942]">
                                  {observationData?.[0]?.observationStatements.map(
                                    (item) => (
                                      <>
                                        <li className="mb-2">{item.name}</li>
                                      </>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {location?.state?.data?.currentScreeningStatus ===
                        "Done" ||
                      (location?.state?.data?.currentScreeningStatus ===
                        "Grade Change" &&
                        location?.state?.data?.activeScreeningCategoryID >
                          2) ? (
                        <>
                          {/* Advance Screening */}
                          {observationData?.[1] && (
                            <>
                              <div>
                                <h3 className="text-[#101942] font-medium text-[18px] text-left mt-5 mb-3">
                                  {observationData[1]?.screeningName}
                                </h3>
                                <ul className="list-disc text-left px-5 text-[14px] text-[#101942]">
                                  {observationData?.[1]?.observationStatements.map(
                                    (item) => (
                                      <>
                                        <li className="mb-2">{item.name}</li>
                                      </>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* Profiler Report */}
              {(location?.state?.data?.currentScreeningStatus === "Done" &&
                location?.state?.data?.activeScreeningCategoryID === 2) ||
              location?.state?.data?.activeScreeningCategoryID > 2 ? (
                <>
                  <div
                    className="bg-[#fff] sm:rounded-[30px] rounded-b-[30px] mt-5"
                    ref={dataRef}
                  >
                    <div className="border-b-[1px] border-[#1d2d7724] py-[15px] px-[20px]">
                      <h3 className="text-left font-semibold text-[#101942] text-[22px]">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) =>
                                item.pageKey === "key_strengths_abilities"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) =>
                                  item.pageKey === "key_strengths_abilities"
                              )[0]?.keyValue
                            : "Strengths and abilities"
                          : "Strengths and abilities"}
                      </h3>
                    </div>
                    <div className="p-5">
                      {profilerReportData?.map((item) => (
                        <>
                          <div>
                            <h3 className="text-[#101942] font-medium text-[18px] text-left my-3">
                              {item?.domainMasterName}
                            </h3>
                            <ul className="list-disc text-left px-5 text-[14px] text-[#101942]">
                              {item?.statements.map((item) => (
                                <>
                                  <li className="mb-2">{item.name}</li>
                                </>
                              ))}
                            </ul>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center h-[80%]">
            <Loader type="spinningBubbles" color="#2e376c" />
          </div>
        </>
      )}
      <>
        <Modal visible={showViewHistoryModal}>
          <div
            className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col"
            style={{
              minHeight: "450px",
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <div
              className="flex rounded-t-[30px]"
              style={{ marginBottom: "15px" }}
            >
              <img
                src={backButton}
                alt="Back Button"
                className="cursor-pointer"
                onClick={() => setShowViewHistoryModal(false)}
              />
              &nbsp; &nbsp;{" "}
              <span
                style={{
                  fontWeight: "800",
                  color: "#313131",
                }}
              >
                History
              </span>
            </div>
            {caseTransferHistoryResponse &&
            caseTransferHistoryResponse.length > 0 ? (
              caseTransferHistoryResponse.map((data) => (
                <div
                  style={{
                    border: "2px solid #ddd",
                    borderRadius: "8px",
                    padding: "5px",
                    width: "340px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    margin: "10px auto",
                    marginTop: "10px",
                  }}
                >
                  <h6
                    className="text-[13px] text-[#565D7A]"
                    style={{ textAlign: "left", margin: "8px" }}
                  >
                    Conducted by
                  </h6>
                  <div style={{ display: "flex", margin: "10px 10px 10px" }}>
                    <img
                      src={
                        data.profilePicURL !== ""
                          ? data.profilePicURL
                          : "../Images/user-history.svg"
                      }
                      alt="icon"
                      className="card-image"
                    />
                    <div style={{ flex: 1 }}>
                      <h4 className="card-subtitle">
                        <b>{data.teacherName}</b>
                      </h4>
                      <p className="card-grade">{data.schoolName}</p>
                    </div>
                    <img
                      src={"../Images/arrow-forward-icon.svg"}
                      alt="icon"
                      className="w-[30px] cursor-pointer"
                      onClick={() => {
                        setShowViewHistoryModal(false);
                        navigate("/user/student-profile-history", {
                          state: dataToPass,
                        });
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div>
                <center>
                  <img
                    src={"../Images/screen1.svg"}
                    alt="icon"
                    className="w-[60%]"
                    style={{ marginTop: "50px" }}
                  />
                  <h1 style={{ fontWeight: "600" }}>No history found..!</h1>
                </center>
              </div>
            )}
          </div>
        </Modal>
      </>
    </>
  );
}

export default PersonalDetails;
