import axios from "axios";
import { baseURL } from "../../../../utils/http/http-common";
import { apiEndPoints } from "../../../../utils/http";

export const CaseTransferProcess = async (data) => {
  try {
    const response = await axios.post(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/CaseTransferProcess`,
      data
    );
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSchoolByStudentDetails = async (
  userId,
  studentName,
  fatherName,
  DOB
) => {
  try {
    const response = await axios.get(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/GetSchoolByStudentDetails/${userId}/${studentName}/${fatherName}`,
      {
        headers: {
          "Content-Type": "application/json",
          dob: DOB,
        },
      }
    );
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const checkAssessedStudent = async (
  userId,
  studentName,
  fatherName,
  schoolId,
  DOB
) => {
  try {
    const response = await axios.get(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/${userId}/${studentName}/${fatherName}/${schoolId}/CheckAssessedStudent`,
      {
        headers: {
          "Content-Type": "application/json",
          dob: DOB,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendCaseTransferRequest = async (data) => {
  try {
    const response = await axios.post(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/SendCaseTransferRequest`,
      data
    );
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const studentCaseTransferHistory = async (studentId) => {
  try {
    const response = await axios.get(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/${studentId}/StudentCaseTransferHistory`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdvanceScreeningQnA = async (studentId, userId, languageId) => {
  try {
    const response = await axios.get(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/${studentId}/${userId}/${languageId}/GetActiveQuestionAdvanceScreening`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 3 Questions : ", error);
    throw error;
  }
};

export const postAdvanceScreeningConcernData = async (data) => {
  try {
    const response = await axios.post(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/PostAdvanceScreeningConcerns`,
      data
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Posting Screeing 3 Data : ", error);
    throw error;
  }
};

export const isVisibleViewHistoryButton = async (studentId) => {
  try {
    const response = await axios.get(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/${studentId}/ButtonBehaviour`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    throw error;
  }
};

export const reScreening = async (studentId, userId) => {
  try {
    const response = await axios.post(
      `https://staging-api-pi.projectinclusion.in/api/CaseTransfer/${studentId}/${userId}/ReScreening`
    );
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};
