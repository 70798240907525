import axios from "axios";
import { baseURL } from "../http/http-common";

export const fetchInstruction = async (pageName, l_id) => {
  try {
    const response = await axios.get(
      `${baseURL}/Instruction/GetInstruction/${l_id}/${pageName}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error.config.data;
  }
};
